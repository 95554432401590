<template>
  <div class="govern-driver">
    <Alert type="warning">排列规则为:驾驶员姓名-驾驶证号-联系电话</Alert>
      <Transfer
        :data="sourceDrivers"
        :target-keys="targetDrivers"
        :filterable="true"
        :list-style="{width: '400px', height: '300px'}"
        @on-change="driverChange"></Transfer>
  </div>
</template>
<script>
import {privilegeMixin, userInfoMixin} from '@/assets/js/mixin.js'
import axios from 'axios'
export default {
  mixins: [privilegeMixin, userInfoMixin],
  props: {
    unitId: {
      type: Number,
      default: () => {
        return 0
      }
    },
    governId: String,
    defaultDrivers: Array
  },
  data () {
    return {
      driversName: [],
      sourceDrivers: [],
      targetDrivers: [],
      searchLoading: false
    }
  },
  created () {
    this.initDrivers()
    this.getDrivers()
  },
  methods: {
    // 初始化驾驶员
    initDrivers () {
      if (this.defaultDrivers.length > 0) {
        this.targetDrivers = this.defaultDrivers.map(item => item.id)
      }
    },
    // 添加驾驶员对象
    async getDrivers () {
      let _params = {
        appKey: this.userInfo.appKey,
        unitId: this.unitId,
        governId: this.governId,
      }
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/getDriver`,
          data: _params
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.sourceDrivers = data.data.map(item => {
            return {
              key: item.id,
              label: `${item.name}-${item.dqId}-${item.tel}`,
              ...item
            }
          })
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取司机信息失败, 请稍后重试!')
        }
      }
    },
    driverChange (newTargetKeys) {
      this.targetDrivers = newTargetKeys
      this.driversName = this.sourceDrivers.filter(item => this.targetDrivers.includes(item.key))
      this.$emit('setDriverName', this.driversName)
    },
  }
}
</script>