<template>
  <div class="ticket-wrapper">
    <div ref="trickDom" class="trick-item" :style="trickItem">
      <header class="ticket-header" :style="ticketHeader">{{title}} <Button @click="print" class="print-btn no-print" type="primary" icon="ios-print">打印</Button></header>
      <section class="ticket-content" style="line-height: 28px;">
        <p class="ticket-no">单号: {{alarmData.governId}}</p>
        <div class="ticket-info" :style="ticketInfo">
          <div class="info-header" :style="infoHeader">
            <div class="info-label" :style="infoLabel">车牌号</div>
            <div class="info-value" :style="infoValue">{{`${alarmData.vehicleNo}(${alarmData.vehiclePlateColor})`}}</div>
            <div class="info-label" :style="infoLabel">所属公司</div>
            <div class="info-value auto-value" :style="{...infoValue, ...autoValue}">{{alarmData.unitName}}</div>
            <div class="info-label" :style="infoLabel">处理类型</div>
            <div class="info-value auto-value" :style="{...infoValue, 'border-right': '0px'}">{{punishWayText.join(',')}}</div>
          </div>
          <template v-for="item in alarmData.contents">
            <div v-if="item.alarms.length > 0 && (item.alarms.some(aItem => aItem.isChecked) || !isFilterAlarmContent)" :key="item.alarmType" class="info-item" style="width: 100%; display: flex;">
              <div class="info-label" :style="contentInfoLabel"><span>{{item.alarmName}}违章情况</span></div>
              <div class="info-value" v-html="setAlarmInfo(item)" :style="contentInfoValue"></div>
            </div>
          </template>
        </div>
      </section>
      <footer class="ticket-footer" style="display: flex; justify-content: space-between;">
        <div class="driver-sign">驾驶员签字:<span class="sigin-text"></span></div>
        <div class="ticket-time">日期:<span style="margin-left: 30px">年</span><span style="margin-left: 30px">月</span><span style="margin-left: 30px">日</span></div>
      </footer>
    </div>
  </div>
</template>
<script>
import {getTimeStrByType} from '@/assets/js/common.js'
import {paramsMixin} from '@/assets/js/mixin.js'
export default {
  mixins: [paramsMixin],
  props: {
    alarmData: Object,
    driversName: Array,
    title: String,
    isFilterAlarmContent: { // 是否按照数据列的ischecked筛选数据
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data () {
    return {
      punishWay: [],
      trickItem: {
        position: 'relative',
        'min-width': '900px',
        'max-width': '1200px',
        height: '100%',
        margin: '0 auto'
      },
      ticketHeader: {
        width: '100%',
        'font-family': '"微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑"',
        'font-weight': 700,
        'font-style': 'normal',
        'font-size': '18px',
        'text-align': 'center'
      },
      ticketInfo: {
        position: 'relative',
        width: '100%',
        border: '1px solid #757575',
      },
      infoHeader: {
        width: '100%',
        height: '28px',
        display: 'flex',
        'border-bottom': '1px solid #757575'
      },
      infoLabel: {
        width: '100px',
        'text-align': 'center',
        padding: '0 3px',
        'border-right': '1px solid #757575'
      },
      infoValue: {
        padding: '0 3px',
        width: '200px',
        'text-align': 'center',
        'border-right': '1px solid #757575'
      },
      autoValue: {
        width: 'auto !important',
        'min-width': '200px',
        padding: '0 5px'
      },

      contentInfoLabel: {
        width: '100px',
        padding: '0 3px',
        'text-align': 'center',
        'vertical-align': 'middle',
        'border-right': '1px solid #757575',
        'border-bottom': '1px solid #757575',
        display: 'flex',
        'align-items': 'center'
      },
      contentInfoValue: {
        width: 'calc(100% - 100px)',
        padding: '0 3px',
        'border-bottom': '1px solid #757575',
        display: 'flex',
        'flex-direction': 'column'
      }
    }
  },
  computed: {
    punishWayText () {
      let result = []
      this.punishWay.forEach(item => {
        this.punishType.forEach(pItem => {
          if (item == pItem.value) {
            result.push(pItem.label)
          }
        })
      })
      return result
    }
  },
  methods: {
    setAlarmInfo(data) {
      let result = ''
      let punishWay = ''
      // 处罚方式
      punishWay = data.editForm.punishId.map(item => {
        if (!this.punishWay.includes(item)) {
          this.punishWay.push(item)
        }
        if (item == 3) {
          return `<span>停班, 停班时长: ${data.editForm.punishTime.length == 0 ? '--' : getTimeStrByType(data.editForm.punishTime[0], '0')} 至 ${data.editForm.punishTime.length == 0 ? '--' : getTimeStrByType(data.editForm.punishTime[1], '0')}</span>`
        } else if (item == 4) {
          return `<span>罚款, 罚款金额: ${data.editForm.punishMoney}</span>`
        } else {
          return `<span>${this.punishType.find(pItem => pItem.value == item).label}</span>`
        }
      })
      // 违章内容
      if(data.alarmType == 500) {
        // 危险驾驶行为
        result = data.alarms.filter( fItem => fItem.isChecked || !this.isFilterAlarmContent).map((item, index) => {
          return `<div class="alarm-item" style="margin: 5px 0; display: flex; flex-direction: column">
                    <span>${index + 1}. ${item.alarmDate}(${item.address}) 发现有${item.alarmTypeRe}违章行为。
                    现对司机 ${this.driversName.map(driverItem => driverItem.label.split('-')[0]).join(',')} 进行</span>
                    ${punishWay.join('')}
                  </div>`
        }).join('')
      } else {
        result = data.alarms.filter(fItem => fItem.isChecked || !this.isFilterAlarmContent).map((item, index) => {
          return (this.isFilterAlarmContent && !item.isChecked) ? '' : `<div class="alarm-item" style="margin: 5px 0; display: flex; flex-direction: column">
                    <span>${index + 1}. ${item.beginDate}(${item.beginAddress ? item.beginAddress : ''}) 至 ${item.endDate}(${item.endAddress ? item.endAddress : ''}) 发现有${data.alarmName}违章行为, 持续时长: ${item.continueTimeRe ? item.continueTimeRe : '--'}。
                    现对司机 ${this.driversName.map(driverItem => driverItem.label.split('-')[0]).join(',')} 进行</span>
                    ${punishWay.join('')}
                  </div>`
        }).join('')
      }
      return result
    },
    // 打印
    print () {
      let _dom = this.$refs.trickDom
      this.$print(_dom)
    }
  }
}
</script>
<style lang="scss" scoped>
.ticket-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  .print-btn {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .trick-item {
    position: relative;
    min-width: 900px;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    .ticket-header {
      width: 100%;
      font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
      font-weight: 700;
      font-style: normal;
      font-size: 18px;
      text-align: center;
    }
    .ticket-content {
      line-height: 28px;
      .ticket-info {
        position: relative;
        width: 100%;
        border: 1px solid #757575;
        .info-header {
          width: 100%;
          height: 28px;
          display: flex;
          border-bottom: 1px solid #757575;
          .info-label {
            width: 100px;
            text-align: center;
            padding: 0 3px;
            border-right: 1px solid #757575;
          }
          .info-value {
            padding: 0 3px;
            width: 200px;
            text-align: center;
            border-right: 1px solid #757575;
          }
          .auto-value {
            width: auto !important;
            min-width: 200px;
            padding: 0 5px;
          }
        }
        .info-item {
          width: 100%;
          display: flex;
          .info-label {
            width: 100px;
            padding: 0 3px;
            text-align: center;
            vertical-align: middle;
            border-right: 1px solid #757575;
            border-bottom: 1px solid #757575;
            display: flex;
            align-items: center;
          }
          .info-value {
            width: calc(100% - 100px);
            padding: 0 3px;
            border-bottom: 1px solid #757575;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .ticket-footer {
      display: flex;
      justify-content: space-between;
    }
  } 
}
</style>