<template>
  <div class="illegal-create common-page">
    <header class="page-header">
      <div @click="goBackForCheck" class="header-left">
        <Icon class="back-icon" type="md-arrow-round-back" />
        <span class="back-title">返回{{params.backTitle}}</span>
      </div>
      <div class="header-title">创建{{createForm.governWay == 1 ? '申诉' : '治理'}}单</div>
    </header>
    <div class="step-wrapper">
      <Steps :current="currentStep" size="small">
        <Step :title="createForm.governWay == 1 ? '创建申诉单' : '创建治理单'"></Step>
        <Step title="编辑"></Step>
        <Step title="提交"></Step>
      </Steps>
    </div>
    <div class="content-wrapper">
      <Spin size="large" fix v-if="searchLoading"></Spin>
      <Carousel v-model="carouselStep" class="carousel-wrapper" dots="none" arrow="never">
        <CarouselItem>
          <!-- step1 新建类型 -->
          <div class="create-list">
            <Form :model="createForm" :label-width="80">
              <FormItem label="类型:">
                <RadioGroup v-model="createForm.governWay">
                  <Radio v-for="item in governWays.slice(0, 2)" :key="item.value" :label="item.value">{{item.label}}</Radio>
                </RadioGroup>
              </FormItem>
              <FormItem label="针对车辆:">
                <RadioGroup v-if="alarmVehicle.length > 0" v-model="createForm.vehicle">
                  <Radio v-for="item in alarmVehicle" :label="item" :key="item"></Radio>
                </RadioGroup>
                <span v-else>暂无违章车辆</span>
              </FormItem>
              <FormItem label="">
                <div class="btn-wrapper">
                  <Button v-if="alarmVehicle.length > 0" @click="createTicket" :disabled="!createForm.vehicle" class="option-btn" type="primary" >下一步</Button>
                </div>
              </FormItem>
            </Form>
          </div>
        </CarouselItem>
        <CarouselItem>
          <!-- step2 编辑通报单 -->
          <div v-if="createForm.vehicle && currentStep > 0" class="common-page">
            <ComplaintEdit v-if="createForm.governWay == 1" :params="{targetRow: createForm, backTitle: '违章详情', backComponentName: 'default'}" :isShowPrevStepBtn="isShowPrevStepBtn" :isShowHeader="false" @goBack="goBack" @goLastStep="hopStep(0)"></ComplaintEdit>
            <IllegalCreateForm v-if="createForm.governWay == 2" :targetParams="createForm" :step="currentStep" :isShowPrevStepBtn="isShowPrevStepBtn" @reSetData="goBack" @hopStep="hopStep"></IllegalCreateForm>
          </div>
        </CarouselItem>
      </Carousel>
    </div>
  </div>
</template>
<script>
import {privilegeMixin, paramsMixin, userInfoMixin} from '@/assets/js/mixin.js'
import ComplaintEdit from "../../complaint/complaint_edit/index.vue"
import IllegalCreateForm from './illegal_create_form/index.vue'
import axios from 'axios'
export default {
  mixins: [privilegeMixin, paramsMixin, userInfoMixin],
  props: {
    params: {
      type: Object,
      default: () => {
        return {
          noticeId: '',
          beginTime: '',
          endTime: '',
          step: 0,
          governWay: 1,
          vehicle: ''
        }
      }
    },
    isShowPrevStepBtn: {
      type: Boolean,
      default: () => {
        return false
      }
    },
  },
  components: {
    ComplaintEdit,
    IllegalCreateForm
  },
  data () {
    return {
      complaintObject:null,
      searchLoading: false,
      currentStep: 0,
      carouselStep: 0, // carouselStep只存在两步
      createForm: {
        noticeId: '',
        beginTime: '',
        endTime: '',
        governWay: 1,
        vehicle: ''
      },
      alarmVehicle: [],
      complaintData: null
    }
  },
  created () {
    this.initStep()
  },
  methods: {
    // 初始化步骤条
    initStep () {
      this.currentStep = this.params.step
      this.carouselStep = this.params.step
      if (this.params.step == 0) {
        this.getAlarmVehicle()
      } else {
        this.initParams()
      }
    },
    // 初始化参数
    initParams () {
      this.createForm.noticeId = this.params.noticeId
      this.createForm.beginTime = this.params.beginTime
      this.createForm.endTime = this.params.endTime
      this.createForm.governWay = this.params.governWay
      this.createForm.vehicle = this.params.vehicle
    },
    // 获取违章车辆
    async getAlarmVehicle () {
      let _params = {
        appKey: this.userInfo.appKey,
        unitId: this.userInfo.unitId,
        beginTime: this.params.beginTime,
        endTime: this.params.endTime
        // noticeId: this.createForm.noticeId,
      }
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/surveybreakrule/breakRulesVehicles`,
          data: _params
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.alarmVehicle = data.data ? data.data.map(item => {
            return `${item.vehicleNo}-${item.vehiclePlateColor}`
          }) : []
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取违章车辆失败, 请稍后重试!')
        }
      }
    },

    // 创建治理单
    createTicket () {
      if (this.createForm.governWay == 1) {
        // 申诉
        this.hopStep(2)
      } else {
        // 治理
        this.hopStep(1)
      }
    },
    hopStep (step) {
      this.currentStep = step
      this.carouselStep = step > 1 ? 1 : step
    },
    goBackForCheck () {
      if (this.currentStep == 2) {
        this.$Modal.confirm({
          title: '返回',
          content: `${this.createForm.governWay == 1 ? '申诉' : '治理'}单尚未建立成功,是否确认离开？`,
          onOk: async () => {
            this.goBack()
          },
          onCancel: () => {}
        })
      } else {
        this.goBack()
      }
    },
    goBack () {
      this.$emit('goBack', this.params.backComponentName)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-detail-page();
.illegal-create {
  position: relative;
  height: 100%;
  width: 100%;
  .step-wrapper {
    width: 100%;
    height: 30px;
  }
  .content-wrapper {
    position: relative;
    width: 100%;
    height: calc(100% - 30px);
    .carousel-wrapper {
      .create-list {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn-wrapper {
          .option-btn {
            margin: 0 5px;
          }
        }
      }
    }
  }
}
</style>